const style = 'unit'
const unitDisplay = 'narrow'

export const MILLIMETER = {
    unit: 'millimeter',
    style,
    unitDisplay,
}
export const CENTIMETER = {
    unit: 'centimeter',
    style,
    unitDisplay,
}
export const METER = {
    unit: 'meter',
    style,
    unitDisplay,
}
export const KILOMETER = {
    unit: 'kilometer',
    style,
    unitDisplay,
}
export const CELSIUS = {
    unit: 'celsius',
    style,
    unitDisplay,
}
export const KILOMETER_PER_HOUR = {
    unit: 'kilometer-per-hour',
    style,
    unitDisplay,
}
export const CENTIMETER_PER_HOUR = {
    unit: 'centimeter-per-hour',
    style,
    unitDisplay,
}
export const DEGREE = {
    unit: 'degree',
    style,
    unitDisplay,
}

export default new Map([
    [MILLIMETER.unit, MILLIMETER],
    [CENTIMETER.unit, CENTIMETER],
    [METER.unit, METER],
    [KILOMETER.unit, KILOMETER],
    [CELSIUS.unit, CELSIUS],
    [KILOMETER_PER_HOUR.unit, KILOMETER_PER_HOUR],
    [CENTIMETER_PER_HOUR.unit, CENTIMETER_PER_HOUR],
    [DEGREE.unit, DEGREE],
])
